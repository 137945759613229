@import '~@gravity-angular/styles/lib/xg-styles.css';

.login-error {
  mat-dialog-container {
    padding: 0;
    overflow: hidden;
  }
}

.login-error-backgorund {
  filter: blur(5px);
  background-color: #16181b;
  opacity: 0.98 !important;
}

.report-tooltip {
  word-break: break-all !important;
  white-space: normal !important;
}

.mat-accent .mat-slider-track-fill {
  background-color: #0085ad !important;
}

.mat-slider-thumb-label {
  background-color: white !important;
  transform: rotate(45deg);
}

.mat-slider-thumb {
  background-color: #0085ad !important;
  transform: scale(1) !important;
}

.mat-slider-thumb-label-text {
  opacity: 1 !important;
}

.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: #55637d !important;
  font-size: 12px !important;
}

.mat-slider-horizontal .mat-slider-thumb-label {
  transform: rotate(45deg) !important;
}

// Increases the size of the * symbol for required form fields
.mat-placeholder-required.mat-form-field-required-marker {
  font-size: 20px;
}

// Handles marking error for customized mat-select dropdown menu
.custom-error:focus-within {
  .mat-form-field-outline {
    color: #aa243c;

    .mat-form-field-outline-start {
      border-width: 3.75px;
    }

    .mat-form-field-outline-gap {
      border-width: 3.75px;
    }

    .mat-form-field-outline-end {
      border-width: 3.75px;
    }
  }
}

.btnMargin {
  height: 50px;
}
